:root {
  --black-color: #000;
  --white-color: #fff;

  --primary-color: #e1fc22;

  --dark-gray: #666666;
  --light-gray: #808080;
  --extra-light-gray: #e6e6e6;

  --form-input-disable: #f4f4f4;

  --background-default: #fafafa;
  --hover-effect: #f1f0f0;

  --blue-color: #0ea5e9;
  --red-alert: #dc2626;
  --purple-color: #6e68fb;

  --light-orange-color: #ffedd5;
  --medium-orange-color: #ea580c;

  --light-green-color: #d1fae5;
  --medium-green-color: #10b981;
}
